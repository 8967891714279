

@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 600px;
@attachWidth: 120px;
.infoBlock {
	width: 100%;
	height: 80px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin-top: @smallSpace;
	padding: @smallSpace 0;
	margin-bottom: @bigSpace;

	.blocks {
		border-radius: 5px;
		color: #FFF;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 22%;
		cursor: pointer;

		&.active {
			filter: drop-shadow(2px 4px 6px black)
		}

		span {
			font-size: 24px;
		}
	}
}

.head {
	margin-bottom: @bigSpace;
}

.detail {
	height: 300px;
	display: flex;
	flex-direction: row;

	.list {
		width: 50%;
		overflow: auto;
		padding: 0 @smallSpace;

		.systemList {
			line-height: 26px;

			li {
				cursor: pointer;
				font-size: @fontSizeD;
			}
		}
	}
}
